<template>
	<section class="elementor-section elementor-top-section elementor-element elementor-element-96bfb0e elementor-section-full_width elementor-section-height-min-height elementor-section-height-default elementor-section-items-middle" data-id="96bfb0e" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
							<div class="elementor-background-overlay"></div>
							<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ca5f127" data-id="ca5f127" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-0dbbbea elementor-widget elementor-widget-theme-page-title elementor-page-title elementor-widget-heading" data-id="0dbbbea" data-element_type="widget" data-widget_type="theme-page-title.default">
				<div class="elementor-widget-container">
					<h1 class="elementor-heading-title elementor-size-default">Why Choose Us</h1>		</div>
				</div>
					</div>
		</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-1ec04a5 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="1ec04a5" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;slideshow&quot;,&quot;background_slideshow_gallery&quot;:[],&quot;background_slideshow_loop&quot;:&quot;yes&quot;,&quot;background_slideshow_slide_duration&quot;:5000,&quot;background_slideshow_slide_transition&quot;:&quot;fade&quot;,&quot;background_slideshow_transition_duration&quot;:500}">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-90ea2ea" data-id="90ea2ea" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
			<div class="elementor-widget-wrap elementor-element-populated">
						<section class="elementor-section elementor-inner-section elementor-element elementor-element-ddf2922 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="ddf2922" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-acafa05" data-id="acafa05" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-8634e9e elementor-view-framed elementor-shape-circle elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="8634e9e" data-element_type="widget" data-widget_type="icon-box.default">
				<div class="elementor-widget-container">
			<link rel="stylesheet" href="../wp-content/plugins/elementor/assets/css/widget-icon-box.min.css">		<div class="elementor-icon-box-wrapper">
						<div class="elementor-icon-box-icon">
				<span  class="elementor-icon elementor-animation-">
				<svg aria-hidden="true" class="e-font-icon-svg e-fas-landmark" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M501.62 92.11L267.24 2.04a31.958 31.958 0 0 0-22.47 0L10.38 92.11A16.001 16.001 0 0 0 0 107.09V144c0 8.84 7.16 16 16 16h480c8.84 0 16-7.16 16-16v-36.91c0-6.67-4.14-12.64-10.38-14.98zM64 192v160H48c-8.84 0-16 7.16-16 16v48h448v-48c0-8.84-7.16-16-16-16h-16V192h-64v160h-96V192h-64v160h-96V192H64zm432 256H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h480c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"></path></svg>				</span>
			</div>
						<div class="elementor-icon-box-content">
				<h3 class="elementor-icon-box-title">
					<span  >
						Market Experts					</span>
				</h3>
									<p class="elementor-icon-box-description">
						We know the ins and outs of Kuala Lumpur's market and can help you make the most of your sales and marketing efforts through extensive experience as a trade house.					</p>
							</div>
		</div>
				</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-6eb7171" data-id="6eb7171" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-7c9bdfa elementor-view-framed elementor-shape-circle elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="7c9bdfa" data-element_type="widget" data-widget_type="icon-box.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-box-wrapper">
						<div class="elementor-icon-box-icon">
				<span  class="elementor-icon elementor-animation-">
				<svg aria-hidden="true" class="e-font-icon-svg e-far-gem" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M464 0H112c-4 0-7.8 2-10 5.4L2 152.6c-2.9 4.4-2.6 10.2.7 14.2l276 340.8c4.8 5.9 13.8 5.9 18.6 0l276-340.8c3.3-4.1 3.6-9.8.7-14.2L474.1 5.4C471.8 2 468.1 0 464 0zm-19.3 48l63.3 96h-68.4l-51.7-96h56.8zm-202.1 0h90.7l51.7 96H191l51.6-96zm-111.3 0h56.8l-51.7 96H68l63.3-96zm-43 144h51.4L208 352 88.3 192zm102.9 0h193.6L288 435.3 191.2 192zM368 352l68.2-160h51.4L368 352z"></path></svg>				</span>
			</div>
						<div class="elementor-icon-box-content">
				<h3 class="elementor-icon-box-title">
					<span  >
						Tailored Solutions					</span>
				</h3>
									<p class="elementor-icon-box-description">
						We customize our services to fit your unique needs, ensuring you get the best results for your investment through our specialized trade house approach.
					</p>
							</div>
		</div>
				</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-e61ee9e" data-id="e61ee9e" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-cd6f9ea elementor-view-framed elementor-shape-circle elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="cd6f9ea" data-element_type="widget" data-widget_type="icon-box.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-box-wrapper">
						<div class="elementor-icon-box-icon">
				<span  class="elementor-icon elementor-animation-">
				<svg aria-hidden="true" class="e-font-icon-svg e-fas-hands-helping" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg"><path d="M488 192H336v56c0 39.7-32.3 72-72 72s-72-32.3-72-72V126.4l-64.9 39C107.8 176.9 96 197.8 96 220.2v47.3l-80 46.2C.7 322.5-4.6 342.1 4.3 357.4l80 138.6c8.8 15.3 28.4 20.5 43.7 11.7L231.4 448H368c35.3 0 64-28.7 64-64h16c17.7 0 32-14.3 32-32v-64h8c13.3 0 24-10.7 24-24v-48c0-13.3-10.7-24-24-24zm147.7-37.4L555.7 16C546.9.7 527.3-4.5 512 4.3L408.6 64H306.4c-12 0-23.7 3.4-33.9 9.7L239 94.6c-9.4 5.8-15 16.1-15 27.1V248c0 22.1 17.9 40 40 40s40-17.9 40-40v-88h184c30.9 0 56 25.1 56 56v28.5l80-46.2c15.3-8.9 20.5-28.4 11.7-43.7z"></path></svg>				</span>
			</div>
						<div class="elementor-icon-box-content">
				<h3 class="elementor-icon-box-title">
					<span  >
						Partnerships					</span>
				</h3>
									<p class="elementor-icon-box-description">
						Our network of partners opens doors for your business, providing valuable connections and growth opportunities, supported by our trade house expertise.
					</p>
							</div>
		</div>
				</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-41677b4" data-id="41677b4" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-45c7332 elementor-view-framed elementor-shape-circle elementor-position-top elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="45c7332" data-element_type="widget" data-widget_type="icon-box.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-box-wrapper">
						<div class="elementor-icon-box-icon">
				<span  class="elementor-icon elementor-animation-">
				<svg aria-hidden="true" class="e-font-icon-svg e-far-paper-plane" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M440 6.5L24 246.4c-34.4 19.9-31.1 70.8 5.7 85.9L144 379.6V464c0 46.4 59.2 65.5 86.6 28.6l43.8-59.1 111.9 46.2c5.9 2.4 12.1 3.6 18.3 3.6 8.2 0 16.3-2.1 23.6-6.2 12.8-7.2 21.6-20 23.9-34.5l59.4-387.2c6.1-40.1-36.9-68.8-71.5-48.9zM192 464v-64.6l36.6 15.1L192 464zm212.6-28.7l-153.8-63.5L391 169.5c10.7-15.5-9.5-33.5-23.7-21.2L155.8 332.6 48 288 464 48l-59.4 387.3z"></path></svg>				</span>
			</div>
						<div class="elementor-icon-box-content">
				<h3 class="elementor-icon-box-title">
					<span  >
						Excellent Execution					</span>
				</h3>
									<p class="elementor-icon-box-description">
						We deliver results you can measure, helping your business stand out in Kuala Lumpur's competitive landscape with our proven trade house strategies.
					</p>
							</div>
		</div>
				</div>
				</div>
					</div>
		</div>
					</div>
		</section>
					</div>
		</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-ed99294 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="ed99294" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-486e578" data-id="486e578" data-element_type="column">
			<div class="elementor-widget-wrap">
							</div>
		</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-5c9c987 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="5c9c987" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0becff3" data-id="0becff3" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-3434404 elementor-widget elementor-widget-image" data-id="3434404" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
													<img decoding="async" width="800" height="454" src="../wp-content/uploads/2024/04/960x0.webp" class="attachment-large size-large wp-image-367" alt="" />													</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-c52d6ef" data-id="c52d6ef" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-f2cdc3a elementor-widget elementor-widget-heading" data-id="f2cdc3a" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h2 class="elementor-heading-title elementor-size-default">Our Mission</h2>		</div>
				</div>
				<div class="elementor-element elementor-element-de63c35 elementor-widget elementor-widget-text-editor" data-id="de63c35" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
					<p><span style="font-weight: 400;">To swiftly connect Russian companies with lucrative opportunities in the ASEAN region, offering comprehensive support for their export endeavors and enhancing their presence in South-East Asian markets.</span></p>						</div>
				</div>
					</div>
		</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-90b394a elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="90b394a" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-47f3013" data-id="47f3013" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-9e67df1 elementor-widget elementor-widget-heading" data-id="9e67df1" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h2 class="elementor-heading-title elementor-size-default">Our Vision</h2>		</div>
				</div>
				<div class="elementor-element elementor-element-61c8752 elementor-widget elementor-widget-text-editor" data-id="61c8752" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
							<p><span style="font-weight: 400;">We envision a future where your business leads the way in Kuala Lumpur&#8217;s market, powered by our strategic insights and support as a premier trade house.</span></p>						</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3c40c2a" data-id="3c40c2a" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-e15a83f elementor-widget elementor-widget-image" data-id="e15a83f" data-element_type="widget" data-widget_type="image.default">
				<div class="elementor-widget-container">
													<img decoding="async" width="800" height="545" src="../wp-content/uploads/2024/04/Business-Vision-The-Fundamentals-Featured-Image-iStock-1018003676-1024x698.webp" class="attachment-large size-large wp-image-368" alt="" />													</div>
				</div>
					</div>
		</div>
					</div>
		</section>
				<section class="elementor-section elementor-top-section elementor-element elementor-element-caacea0 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="caacea0" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7e71072" data-id="7e71072" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-f6c3268 elementor-widget elementor-widget-heading" data-id="f6c3268" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h2 class="elementor-heading-title elementor-size-default">OUR <span style="color:#0563c7">TEAM</span></h2>		</div>
				</div>
				<section class="elementor-section elementor-inner-section elementor-element elementor-element-a584525 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="a584525" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-6999a47" data-id="6999a47" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-a08fa4d elementor-position-top elementor-position-top elementor-widget elementor-widget-image-box" data-id="a08fa4d" data-element_type="widget" data-widget_type="image-box.default">
				<div class="elementor-widget-container">
<div class="elementor-image-box-wrapper"><figure class="elementor-image-box-img"><img loading="lazy" decoding="async" width="1080" height="1080" src="../wp-content/uploads/2024/04/4.png" class="attachment-full size-full wp-image-221" alt="" /></figure><div class="elementor-image-box-content"><h3 class="elementor-image-box-title">Andrey Kabunka</h3><p class="elementor-image-box-description">Head of the Russian Office</p></div></div>		</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-93f2deb" data-id="93f2deb" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-db768bd elementor-position-top elementor-position-top elementor-widget elementor-widget-image-box" data-id="db768bd" data-element_type="widget" data-widget_type="image-box.default">
				<div class="elementor-widget-container">
			<div class="elementor-image-box-wrapper"><figure class="elementor-image-box-img"><img loading="lazy" decoding="async" width="1080" height="1080" src="../wp-content/uploads/2024/04/3.png" class="attachment-full size-full wp-image-220" alt="" /></figure><div class="elementor-image-box-content"><h3 class="elementor-image-box-title">Daniyar Yunusmetov </h3><p class="elementor-image-box-description">Head of Malaysian Office</p></div></div>		</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-141276b" data-id="141276b" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-c2dacc1 elementor-position-top elementor-position-top elementor-widget elementor-widget-image-box" data-id="c2dacc1" data-element_type="widget" data-widget_type="image-box.default">
				<div class="elementor-widget-container">
			<div class="elementor-image-box-wrapper"><figure class="elementor-image-box-img"><img loading="lazy" decoding="async" width="1080" height="1080" src="../wp-content/uploads/2024/04/2-1.png" class="attachment-full size-full wp-image-219" alt="" /></figure><div class="elementor-image-box-content"><h3 class="elementor-image-box-title">Elena Rudavina</h3><p class="elementor-image-box-description">Financial Department Manager</p></div></div>		</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-8b69a4b" data-id="8b69a4b" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-83874d9 elementor-position-top elementor-position-top elementor-widget elementor-widget-image-box" data-id="83874d9" data-element_type="widget" data-widget_type="image-box.default">
				<div class="elementor-widget-container">
			<div class="elementor-image-box-wrapper"><figure class="elementor-image-box-img"><img loading="lazy" decoding="async" width="1080" height="1080" src="../wp-content/uploads/2024/04/1.png" class="attachment-full size-full wp-image-218" alt="" /></figure><div class="elementor-image-box-content"><h3 class="elementor-image-box-title">Isa Isgenderov</h3><p class="elementor-image-box-description">Leading Specialist of The Logistics Department</p></div></div>		</div>
				</div>
					</div>
		</div>
					</div>
		</section>
					</div>
		</div>
					</div>
		</section>
		<FeedbackFormComponent />
		<ContactsComponent />
</template>

<script>
import FeedbackFormComponent from '@/components/FeedbackFormComponent.vue';
import ContactsComponent from '@/components/ContactsComponent.vue';

export default {
    name: "WhyUsView",
	components: {
		FeedbackFormComponent,
		ContactsComponent
	}
}
</script>

<style scoped lang="scss">

.elementor-widget-image-box .elementor-image-box-content{width:100%}@media (min-width:768px){.elementor-widget-image-box.elementor-position-left .elementor-image-box-wrapper,.elementor-widget-image-box.elementor-position-right .elementor-image-box-wrapper{display:flex}.elementor-widget-image-box.elementor-position-right .elementor-image-box-wrapper{text-align:right;flex-direction:row-reverse}.elementor-widget-image-box.elementor-position-left .elementor-image-box-wrapper{text-align:left;flex-direction:row}.elementor-widget-image-box.elementor-position-top .elementor-image-box-img{margin:auto}.elementor-widget-image-box.elementor-vertical-align-top .elementor-image-box-wrapper{align-items:flex-start}.elementor-widget-image-box.elementor-vertical-align-middle .elementor-image-box-wrapper{align-items:center}.elementor-widget-image-box.elementor-vertical-align-bottom .elementor-image-box-wrapper{align-items:flex-end}}@media (max-width:767px){.elementor-widget-image-box .elementor-image-box-img{margin-left:auto!important;margin-right:auto!important;margin-bottom:15px}}.elementor-widget-image-box .elementor-image-box-img{display:inline-block}.elementor-widget-image-box .elementor-image-box-title a{color:inherit}.elementor-widget-image-box .elementor-image-box-wrapper{text-align:center}.elementor-widget-image-box .elementor-image-box-description{margin:0}
.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap{background-color:#69727d;color:#fff}.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap{color:#69727d;border:3px solid;background-color:transparent}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap{margin-top:8px}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter{width:1em;height:1em}.elementor-widget-text-editor .elementor-drop-cap{float:left;text-align:center;line-height:1;font-size:50px}.elementor-widget-text-editor .elementor-drop-cap-letter{display:inline-block}
.elementor-heading-title{padding:0;margin:0;line-height:1}.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a{color:inherit;font-size:inherit;line-height:inherit}.elementor-widget-heading .elementor-heading-title.elementor-size-small{font-size:15px}.elementor-widget-heading .elementor-heading-title.elementor-size-medium{font-size:19px}.elementor-widget-heading .elementor-heading-title.elementor-size-large{font-size:29px}.elementor-widget-heading .elementor-heading-title.elementor-size-xl{font-size:39px}.elementor-widget-heading .elementor-heading-title.elementor-size-xxl{font-size:59px}


</style>