<template>
	<section class="elementor-section elementor-top-section elementor-element elementor-element-5a94a88 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="5a94a88" data-element_type="section">
							<div class="elementor-background-overlay"></div>
							<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-be97ceb" data-id="be97ceb" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-be0178b elementor-invisible elementor-widget elementor-widget-heading" data-id="be0178b" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;slideInDown&quot;}" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h2 class="elementor-heading-title elementor-size-default">OUR TEAM</h2>		</div>
				</div>
				<section class="elementor-section elementor-inner-section elementor-element elementor-element-4550108 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4550108" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-df0dbc4 elementor-invisible" data-id="df0dbc4" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;slideInLeft&quot;}">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-625feff elementor-position-top elementor-position-top elementor-widget elementor-widget-image-box" data-id="625feff" data-element_type="widget" data-widget_type="image-box.default">
				<div class="elementor-widget-container">
			<div class="elementor-image-box-wrapper"><figure class="elementor-image-box-img"><img loading="lazy" decoding="async" width="1080" height="1080" src="@/wp-content/uploads/2024/04/4.png" class="attachment-full size-full wp-image-221" alt="" /></figure><div class="elementor-image-box-content"><h3 class="elementor-image-box-title">Andrey Kabushka</h3><p class="elementor-image-box-description">Head of Russian Office</p></div></div>		</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-1134955 elementor-invisible" data-id="1134955" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;slideInLeft&quot;}">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-b334818 elementor-position-top elementor-position-top elementor-widget elementor-widget-image-box" data-id="b334818" data-element_type="widget" data-widget_type="image-box.default">
				<div class="elementor-widget-container">
			<div class="elementor-image-box-wrapper"><figure class="elementor-image-box-img"><img loading="lazy" decoding="async" width="1080" height="1080" src="@/wp-content/uploads/2024/04/3.png" class="attachment-full size-full wp-image-220" alt="" /></figure><div class="elementor-image-box-content"><h3 class="elementor-image-box-title">Daniyar Yunusmetov </h3><p class="elementor-image-box-description">Head of Malaysian Office</p></div></div>		</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-b7d5722 elementor-invisible" data-id="b7d5722" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;slideInLeft&quot;}">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-bd08577 elementor-position-top elementor-widget__width-initial elementor-position-top elementor-widget elementor-widget-image-box" data-id="bd08577" data-element_type="widget" data-widget_type="image-box.default">
				<div class="elementor-widget-container">
			<div class="elementor-image-box-wrapper"><figure class="elementor-image-box-img"><img loading="lazy" decoding="async" width="768" height="768" src="@/wp-content/uploads/2024/04/2-1-768x768.png" class="attachment-medium_large size-medium_large wp-image-219" alt="" /></figure><div class="elementor-image-box-content"><h3 class="elementor-image-box-title"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Елена </font></font><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Рудавина</font></font><br></h3><p class="elementor-image-box-description">Financial Department Manager</p></div></div>		</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-e92cfdc elementor-invisible" data-id="e92cfdc" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;slideInLeft&quot;}">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-ecf6437 elementor-position-top elementor-position-top elementor-widget elementor-widget-image-box" data-id="ecf6437" data-element_type="widget" data-widget_type="image-box.default">
				<div class="elementor-widget-container">
			<div class="elementor-image-box-wrapper"><figure class="elementor-image-box-img"><img loading="lazy" decoding="async" width="1080" height="1080" src="@/wp-content/uploads/2024/04/1.png" class="attachment-full size-full wp-image-218" alt="" /></figure><div class="elementor-image-box-content"><h3 class="elementor-image-box-title">Isa Isgenderov</h3><p class="elementor-image-box-description">Logistics Department Manager</p></div></div>		</div>
				</div>
					</div>
		</div>
					</div>
		</section>
				<div class="elementor-element elementor-element-145d93f elementor-widget elementor-widget-heading" data-id="145d93f" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h2 class="elementor-heading-title elementor-size-default">GET IN TOUCH WITH US</h2>		</div>
				</div>
				<div class="elementor-element elementor-element-185339e elementor-button-align-stretch elementor-widget elementor-widget-form" data-id="185339e" data-element_type="widget" data-settings="{&quot;button_width&quot;:&quot;50&quot;,&quot;step_next_label&quot;:&quot;Next&quot;,&quot;step_previous_label&quot;:&quot;Previous&quot;,&quot;step_type&quot;:&quot;number_text&quot;,&quot;step_icon_shape&quot;:&quot;circle&quot;}" data-widget_type="form.default">
				<div class="elementor-widget-container">
<form class="elementor-form" method="post" name="New Form">
			<input type="hidden" name="post_id" value="59"/>
			<input type="hidden" name="form_id" value="185339e"/>
			<input type="hidden" name="referer_title" value="" />

							<input type="hidden" name="queried_id" value="59"/>
			
			<div class="elementor-form-fields-wrapper elementor-labels-above">
								<div class="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-name elementor-col-25">
												<label for="form-field-name" class="elementor-field-label">
								Name							</label>
														<input size="1" type="text" name="form_fields[name]" id="form-field-name" class="elementor-field elementor-size-sm  elementor-field-textual" placeholder="Name">
											</div>
								<div class="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-field_7788185 elementor-col-25">
												<label for="form-field-field_7788185" class="elementor-field-label">
								Company							</label>
														<input size="1" type="text" name="form_fields[field_7788185]" id="form-field-field_7788185" class="elementor-field elementor-size-sm  elementor-field-textual" placeholder="Company">
											</div>
								<div class="elementor-field-type-tel elementor-field-group elementor-column elementor-field-group-field_9c095a4 elementor-col-25">
												<label for="form-field-field_9c095a4" class="elementor-field-label">
								Phone							</label>
								<input size="1" type="tel" name="form_fields[field_9c095a4]" id="form-field-field_9c095a4" class="elementor-field elementor-size-sm  elementor-field-textual" placeholder="Phone" pattern="[0-9()#&amp;+*-=.]+" title="Only numbers and phone characters (#, -, *, etc) are accepted.">

						</div>
								<div class="elementor-field-type-email elementor-field-group elementor-column elementor-field-group-email elementor-col-25 elementor-field-required">
												<label for="form-field-email" class="elementor-field-label">
								Email							</label>
														<input size="1" type="email" name="form_fields[email]" id="form-field-email" class="elementor-field elementor-size-sm  elementor-field-textual" placeholder="Email" required="required" aria-required="true">
											</div>
								<div class="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-field_7f34cb6 elementor-col-50">
												<label for="form-field-field_7f34cb6" class="elementor-field-label">
								Your Question							</label>
														<input size="1" type="text" name="form_fields[field_7f34cb6]" id="form-field-field_7f34cb6" class="elementor-field elementor-size-sm  elementor-field-textual" placeholder="Your Question">
											</div>
								<div class="elementor-field-group elementor-column elementor-field-type-submit elementor-col-50 e-form__buttons">
					<button type="submit" class="elementor-button elementor-size-sm">
						<span >
															<span class=" elementor-button-icon">
																										</span>
																						<span class="elementor-button-text">Send</span>
													</span>
					</button>
				</div>
			</div>
		</form>
				</div>
				</div>
					</div>
		</div>
					</div>
		</section>
  </template>
  
  <script>
  export default {
      name: "OurTeamComponent"
  }
  </script>
  
  <style scoped lang="scss">
	
  </style>