<template>
		<CommentNewsComponent />
</template>

<script>
import CommentNewsComponent from '@/components/CommentNewsComponent.vue';

export default {
    name: "NewsView",
	components: {
		CommentNewsComponent
	}
}
</script>

<style scoped lang="scss">

</style>