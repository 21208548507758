<template>
  <HeaderComponent />
  <router-view />
  <FooterComponent />
</template>

<script>
import HeaderComponent from "./components/HeaderComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";

export default {
    name: "App",
    components: {
        HeaderComponent,
        FooterComponent
    },
    mounted() {
      // Получаем все элементы с атрибутом data-settings
      const elements = document.querySelectorAll('[data-settings]');

      // Проходим по всем элементам
      elements.forEach((element) => {
        console.log
        const settings = JSON.parse(element.getAttribute('data-settings'));
        const animation = settings.animation;

        element.style.animation = `${animation} 1s`;
        element.style.visibility = `visible`;

      });
    }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
</style>
