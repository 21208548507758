<template>
    <section class="elementor-section elementor-top-section elementor-element elementor-element-4f55db95 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible" data-id="4f55db95" data-element_type="section" id="news" data-settings="{&quot;animation&quot;:&quot;slideInUp&quot;}">
							<div class="elementor-background-overlay"></div>
							<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-322acac7" data-id="322acac7" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-46235692 elementor-widget elementor-widget-heading" data-id="46235692" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h2 class="elementor-heading-title elementor-size-default">NEWS</h2>		</div>
				</div>
				<div class="elementor-element elementor-element-51a4c9de elementor-grid-4 elementor-grid-tablet-2 elementor-grid-mobile-1 elementor-posts--thumbnail-top elementor-widget elementor-widget-posts" data-id="51a4c9de" data-element_type="widget" data-settings="{&quot;classic_columns&quot;:&quot;4&quot;,&quot;classic_columns_tablet&quot;:&quot;2&quot;,&quot;classic_columns_mobile&quot;:&quot;1&quot;,&quot;classic_row_gap&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:35,&quot;sizes&quot;:[]},&quot;classic_row_gap_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;classic_row_gap_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}" data-widget_type="posts.classic">
				<div class="elementor-widget-container"><div class="elementor-posts-container elementor-posts elementor-posts--skin-classic elementor-grid elementor-has-item-ratio">
				<article class="elementor-post elementor-grid-item post-525 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized">
				<a class="elementor-post__thumbnail__link" href="an-online-meeting-with-the-participation-of-russian-and-malaysian-businesses-is-planned-in-mid-july/index.html" tabindex="-1" >
			<div class="elementor-post__thumbnail"><img loading="lazy" decoding="async" width="300" height="200" src="@/wp-content/uploads/2024/07/%d0%9e%d0%bd%d0%bb%d0%b0%d0%b8%cc%86%d0%bd-%d0%b2%d1%81%d1%82%d1%80%d0%b5%d1%87%d0%b0-300x200.jpg" class="attachment-medium size-medium wp-image-515" alt="" /></div>
		</a>
				<div class="elementor-post__text">
				<h3 class="elementor-post__title">
			<router-link to="/news">
				An online meeting with the participation of Russian and Malaysian businesses is planned in mid-July.			</router-link>
		</h3>
				<div class="elementor-post__meta-data">
					<span class="elementor-post-date">
			July 4, 2024		</span>
				<span class="elementor-post-avatar">
			No Comments		</span>
				</div>
		
		<a class="elementor-post__read-more" href="an-online-meeting-with-the-participation-of-russian-and-malaysian-businesses-is-planned-in-mid-july/index.html" aria-label="Read more about An online meeting with the participation of Russian and Malaysian businesses is planned in mid-July." tabindex="-1" >
			Read More »		</a>

				</div>
				</article>
				<article class="elementor-post elementor-grid-item post-523 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized">
				<a class="elementor-post__thumbnail__link" href="an-agreement-was-reached-with-mardec-on-representing-interests-in-russia-and-the-cis-countries/index.html" tabindex="-1" >
			<div class="elementor-post__thumbnail"><img loading="lazy" decoding="async" width="212" height="300" src="@/wp-content/uploads/2024/07/%d0%9f%d0%b8%d1%81%d1%8c%d0%bc%d0%be-%d0%9c%d0%90%d0%a0%d0%94%d0%95%d0%9a-212x300.jpg" class="attachment-medium size-medium wp-image-516" alt="" /></div>
		</a>
				<div class="elementor-post__text">
				<h3 class="elementor-post__title">
			<router-link to="/news">
				An agreement was reached with MARDEC on representing interests in Russia and the CIS countries.			</router-link>
		</h3>
				<div class="elementor-post__meta-data">
					<span class="elementor-post-date">
			July 1, 2024		</span>
				<span class="elementor-post-avatar">
			No Comments		</span>
				</div>
		
		<a class="elementor-post__read-more" href="an-agreement-was-reached-with-mardec-on-representing-interests-in-russia-and-the-cis-countries/index.html" aria-label="Read more about An agreement was reached with MARDEC on representing interests in Russia and the CIS countries." tabindex="-1" >
			Read More »		</a>

				</div>
				</article>
				<article class="elementor-post elementor-grid-item post-521 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized">
				<a class="elementor-post__thumbnail__link" href="celebrating-russia-day-in-malaysia/index.html" tabindex="-1" >
			<div class="elementor-post__thumbnail"><img loading="lazy" decoding="async" width="225" height="300" src="@/wp-content/uploads/2024/07/%d0%94%d0%b5%d0%bd%d1%8c-%d0%a0%d0%be%d1%81%d1%81%d0%b8%d0%b8-225x300.jpg" class="attachment-medium size-medium wp-image-517" alt="" /></div>
		</a>
				<div class="elementor-post__text">
				<h3 class="elementor-post__title">
			<router-link to="/news">
				Celebrating Russia Day in Malaysia			</router-link>
		</h3>
				<div class="elementor-post__meta-data">
					<span class="elementor-post-date">
			June 12, 2024		</span>
				<span class="elementor-post-avatar">
			No Comments		</span>
				</div>
		
		<a class="elementor-post__read-more" href="celebrating-russia-day-in-malaysia/index.html" aria-label="Read more about Celebrating Russia Day in Malaysia" tabindex="-1" >
			Read More »		</a>

				</div>
				</article>
				<article class="elementor-post elementor-grid-item post-514 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized">
				<a class="elementor-post__thumbnail__link" href="meeting-at-the-russian-embassy-in-malaysia/index.html" tabindex="-1" >
			<div class="elementor-post__thumbnail"><img loading="lazy" decoding="async" width="300" height="225" src="@/wp-content/uploads/2024/07/%d0%92%d1%81%d1%82%d1%80%d0%b5%d1%87%d0%b0-%d0%bf%d0%be%d1%81%d0%be%d0%bb%d1%8c%d1%81%d1%82%d0%b2%d0%be-300x225.jpg" class="attachment-medium size-medium wp-image-518" alt="" /></div>
		</a>
				<div class="elementor-post__text">
				<h3 class="elementor-post__title">
			<router-link to="/news">
				Meeting at the Russian Embassy in Malaysia			</router-link>
		</h3>
				<div class="elementor-post__meta-data">
					<span class="elementor-post-date">
			May 7, 2024		</span>
				<span class="elementor-post-avatar">
			No Comments		</span>
				</div>
		
		<a class="elementor-post__read-more" href="meeting-at-the-russian-embassy-in-malaysia/index.html" aria-label="Read more about Meeting at the Russian Embassy in Malaysia" tabindex="-1" >
			Read More »		</a>

				</div>
				</article>
				</div>
		
				</div>
				</div>
					</div>
		</div>
					</div>
		</section>
  </template>
  
  <script>
  export default {
      name: "NewsListComponent"
  }
  </script>
  
  <style scoped lang="scss">
  </style>