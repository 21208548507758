<template>
<main id="content" class="site-main post-59 page type-page status-publish hentry">
	
	<div class="page-content">
				<div data-elementor-type="wp-page" data-elementor-id="59" class="elementor elementor-59" data-elementor-post-type="page">
						<section class="elementor-section elementor-top-section elementor-element elementor-element-da8d86f elementor-section-height-full elementor-section-boxed elementor-section-height-default elementor-section-items-middle" data-id="da8d86f" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
							<div class="elementor-background-overlay"></div>
							<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-24c75ad3 elementor-invisible" data-id="24c75ad3" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;slideInDown&quot;}">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-d225ae9 elementor-widget elementor-widget-heading" data-id="d225ae9" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
<h2 class="elementor-heading-title elementor-size-default">Trade House in Malaysia</h2>		</div>
				</div>
				<div class="elementor-element elementor-element-7093869 elementor-widget__width-initial elementor-widget elementor-widget-text-editor" data-id="7093869" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
<p v-html="page.top"></p>						</div>
				</div>
					</div>
		</div>
					</div>
		</section>
				
				<AdvantagesComponent />
				<CoreBusinessComponent />
				<PartnersComponent />
				<NewsListComponent />
				<BenefitsComponent />
				<OurTeamComponent />
				<ContactsComponent />
				
				
				<section class="elementor-section elementor-top-section elementor-element elementor-element-3f307a82 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="3f307a82" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2a8705e" data-id="2a8705e" data-element_type="column">
			<div class="elementor-widget-wrap">
							</div>
		</div>
					</div>
		</section>
				</div>
				<div class="post-tags">
					</div>
			</div>

	
</main>
</template>

<script>
import NewsListComponent from '@/components/NewsListComponent.vue';
import AdvantagesComponent from '@/components/AdvantagesComponent.vue';
import BenefitsComponent from '@/components/BenefitsComponent.vue';
import PartnersComponent from '@/components/PartnersComponent.vue';
import CoreBusinessComponent from '@/components/CoreBusinessComponent.vue';
import OurTeamComponent from '@/components/OurTeamComponent.vue';
import ContactsComponent from '@/components/ContactsComponent.vue';

export default {
    name: "HomeView",
	components: {
		NewsListComponent,
		BenefitsComponent,
		PartnersComponent,
		CoreBusinessComponent,
		OurTeamComponent,
		ContactsComponent,
		AdvantagesComponent
	},
	data() {
		return {
			page: "",
		}
	},
  mounted() {
    this.fetchContent();
  },
  methods: {
    async fetchContent() {
      try {
        const response = await fetch(process.env.VUE_APP_API_URL + `/api/get/tradehouse/${this.$langStore.lang}/home`);
        if (!response.ok) throw new Error('Ошибка запроса');
        const data = await response.json();
		this.page = data;
		console.log(this.page)
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    }
  }
}
</script>

<style scoped lang="scss">

  .first-block {
    background-image: url('@/img/header-bg.jpg');
    background-attachment: fixed;
    height: 100dvh;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 3rem;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, .5);
      pointer-events: none;
      z-index: 0;
    }

    .title {
      width: 55%;
      margin: 0 auto;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      text-align: start;
      gap: 1rem;
      z-index: 1;

      h1 {
        font-size: clamp(2rem, 5vw, 5rem);
      }

      span {
        font-size: 1.5rem;
        margin-block-start: 0;
      }
    }
  }

</style>