<template>
	<section class="elementor-section elementor-top-section elementor-element elementor-element-9bea7f9 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible" data-id="9bea7f9" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;slideInUp&quot;}">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-201d924" data-id="201d924" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-423397aa elementor-widget elementor-widget-google_maps" data-id="423397aa" data-element_type="widget" data-widget_type="google_maps.default">
				<div class="elementor-widget-container">
          <div class="elementor-custom-embed">
			<iframe loading="lazy"
					src="https://maps.google.com/maps?q=1-13-02%2C%20AURORA%20SOVO%2C%20PLAZA%20BUKIT%20JALIL%2C%20NO.%201%2C%20PERSIARAN%20JALIL%201%2C%20BANDAR%20BUKIT%20JALIL%2C%2057000%2C%20KUALA%20LUMPUR%2C%20MALAYSIA&amp;t=m&amp;z=13&amp;output=embed&amp;iwloc=near"
					title="1-13-02, AURORA SOVO, PLAZA BUKIT JALIL, NO. 1, PERSIARAN JALIL 1, BANDAR BUKIT JALIL, 57000, KUALA LUMPUR, MALAYSIA"
					aria-label="1-13-02, AURORA SOVO, PLAZA BUKIT JALIL, NO. 1, PERSIARAN JALIL 1, BANDAR BUKIT JALIL, 57000, KUALA LUMPUR, MALAYSIA"
			></iframe>
		</div>
				</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8e80470" data-id="8e80470" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-46a1aed8 elementor-widget elementor-widget-heading" data-id="46a1aed8" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h2 class="elementor-heading-title elementor-size-default">OUR <span style="color:#0563c7">CONTACTS</span></h2>		</div>
				</div>
				<div class="elementor-element elementor-element-52a0e514 elementor-align-center elementor-widget__width-initial elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="52a0e514" data-element_type="widget" data-widget_type="icon-list.default">
				<div class="elementor-widget-container">
					<ul class="elementor-icon-list-items">
							<li class="elementor-icon-list-item">
											<span class="elementor-icon-list-icon">
							<svg aria-hidden="true" class="e-font-icon-svg e-fas-map-pin" viewBox="0 0 288 512" xmlns="http://www.w3.org/2000/svg"><path d="M112 316.94v156.69l22.02 33.02c4.75 7.12 15.22 7.12 19.97 0L176 473.63V316.94c-10.39 1.92-21.06 3.06-32 3.06s-21.61-1.14-32-3.06zM144 0C64.47 0 0 64.47 0 144s64.47 144 144 144 144-64.47 144-144S223.53 0 144 0zm0 76c-37.5 0-68 30.5-68 68 0 6.62-5.38 12-12 12s-12-5.38-12-12c0-50.73 41.28-92 92-92 6.62 0 12 5.38 12 12s-5.38 12-12 12z"></path></svg>						</span>
										<span class="elementor-icon-list-text">1-13-02, AURORA SOVO, PLAZA BUKIT JALIL, NO. 1, PERSIARAN JALIL 1, BANDAR BUKIT JALIL, 57000, KUALA LUMPUR, MALAYSIA</span>
									</li>
						</ul>
				</div>
				</div>
				<div class="elementor-element elementor-element-eb6cc80 elementor-icon-list--layout-inline elementor-align-center elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="eb6cc80" data-element_type="widget" data-widget_type="icon-list.default">
				<div class="elementor-widget-container">
					<ul class="elementor-icon-list-items elementor-inline-items">
							<li class="elementor-icon-list-item elementor-inline-item">
											<span class="elementor-icon-list-icon">
							<svg aria-hidden="true" class="e-font-icon-svg e-fas-phone-alt" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path></svg>						</span>
										<span class="elementor-icon-list-text">+60 147-005810</span>
									</li>
								<li class="elementor-icon-list-item elementor-inline-item">
											<span class="elementor-icon-list-icon">
							<svg aria-hidden="true" class="e-font-icon-svg e-far-envelope" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"></path></svg>						</span>
										<span class="elementor-icon-list-text">info@adasigroup.com</span>
									</li>
						</ul>
				</div>
				</div>
				
					</div>
		</div>
					</div>
		</section>
  </template>
  
  <script>
  export default {
      name: "ContactsComponent"
  }
  </script>
  
  <style scoped lang="scss">
	
  </style>