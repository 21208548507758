<template>
					<section class="elementor-section elementor-top-section elementor-element elementor-element-6786205 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="6786205" data-element_type="section" id="team">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4e82e93" data-id="4e82e93" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-ed15cdc elementor-invisible elementor-widget elementor-widget-heading" data-id="ed15cdc" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;slideInDown&quot;}" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h2 class="elementor-heading-title elementor-size-default">OUR <span style="color:#0563c7">PARTNERS</span></h2>		</div>
				</div>
				<div class="elementor-element elementor-element-d07ed37 elementor-invisible elementor-widget elementor-widget-image-gallery" data-id="d07ed37" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;slideInLeft&quot;}" data-widget_type="image-gallery.default">
				<div class="elementor-widget-container">
<div class="elementor-image-gallery">
			<div id='gallery-1' class='gallery galleryid-59 gallery-columns-4 gallery-size-thumbnail'><figure class='gallery-item'>
			<div class='gallery-icon landscape'>
				<a data-elementor-open-lightbox="yes" data-elementor-lightbox-slideshow="d07ed37" data-elementor-lightbox-title="istituthalifa" data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MTcyLCJ1cmwiOiJodHRwczpcL1wvdHJhZGVob3VzZS1tYWxheXNpYS5jb21cL3dwLWNvbnRlbnRcL3VwbG9hZHNcLzIwMjRcLzA0XC9pc3RpdHV0aGFsaWZhLnBuZyIsInNsaWRlc2hvdyI6ImQwN2VkMzcifQ%3D%3D" href='wp-content/uploads/2024/04/istituthalifa.png'><img loading="lazy" decoding="async" width="150" height="150" src="../wp-content/uploads/2024/04/istituthalifa-150x150.png" class="attachment-thumbnail size-thumbnail" alt="" /></a>
			</div></figure><figure class='gallery-item'>
			<div class='gallery-icon landscape'>
				<a data-elementor-open-lightbox="yes" data-elementor-lightbox-slideshow="d07ed37" data-elementor-lightbox-title="masdar" data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MTc0LCJ1cmwiOiJodHRwczpcL1wvdHJhZGVob3VzZS1tYWxheXNpYS5jb21cL3dwLWNvbnRlbnRcL3VwbG9hZHNcLzIwMjRcLzA0XC9tYXNkYXIucG5nIiwic2xpZGVzaG93IjoiZDA3ZWQzNyJ9" href='wp-content/uploads/2024/04/masdar.png'><img loading="lazy" decoding="async" width="150" height="150" src="../wp-content/uploads/2024/04/masdar-150x150.png" class="attachment-thumbnail size-thumbnail" alt="" /></a>
			</div></figure><figure class='gallery-item'>
			<div class='gallery-icon landscape'>
				<a data-elementor-open-lightbox="yes" data-elementor-lightbox-slideshow="d07ed37" data-elementor-lightbox-title="dmcc" data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MTc1LCJ1cmwiOiJodHRwczpcL1wvdHJhZGVob3VzZS1tYWxheXNpYS5jb21cL3dwLWNvbnRlbnRcL3VwbG9hZHNcLzIwMjRcLzA0XC9kbWNjLnBuZyIsInNsaWRlc2hvdyI6ImQwN2VkMzcifQ%3D%3D" href='wp-content/uploads/2024/04/dmcc.png'><img loading="lazy" decoding="async" width="150" height="150" src="../wp-content/uploads/2024/04/dmcc-150x150.png" class="attachment-thumbnail size-thumbnail" alt="" /></a>
			</div></figure><figure class='gallery-item'>
			<div class='gallery-icon landscape'>
				<a data-elementor-open-lightbox="yes" data-elementor-lightbox-slideshow="d07ed37" data-elementor-lightbox-title="rakez1" data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MTc2LCJ1cmwiOiJodHRwczpcL1wvdHJhZGVob3VzZS1tYWxheXNpYS5jb21cL3dwLWNvbnRlbnRcL3VwbG9hZHNcLzIwMjRcLzA0XC9yYWtlejEucG5nIiwic2xpZGVzaG93IjoiZDA3ZWQzNyJ9" href='wp-content/uploads/2024/04/rakez1.png'><img loading="lazy" decoding="async" width="150" height="150" src="../wp-content/uploads/2024/04/rakez1-150x150.png" class="attachment-thumbnail size-thumbnail" alt="" /></a>
			</div></figure><figure class='gallery-item'>
			<div class='gallery-icon landscape'>
				<a data-elementor-open-lightbox="yes" data-elementor-lightbox-slideshow="d07ed37" data-elementor-lightbox-title="Снимок экрана 2024-07-03 в 11.21.55" data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NTI3LCJ1cmwiOiJodHRwczpcL1wvdHJhZGVob3VzZS1tYWxheXNpYS5jb21cL3dwLWNvbnRlbnRcL3VwbG9hZHNcLzIwMjRcLzA3XC9cdTA0MjFcdTA0M2RcdTA0MzhcdTA0M2NcdTA0M2VcdTA0M2EtXHUwNDRkXHUwNDNhXHUwNDQwXHUwNDMwXHUwNDNkXHUwNDMwLTIwMjQtMDctMDMtXHUwNDMyLTExLjIxLjU1LnBuZyIsInNsaWRlc2hvdyI6ImQwN2VkMzcifQ%3D%3D" href='wp-content/uploads/2024/07/%d0%a1%d0%bd%d0%b8%d0%bc%d0%be%d0%ba-%d1%8d%d0%ba%d1%80%d0%b0%d0%bd%d0%b0-2024-07-03-%d0%b2-11.21.55.png'><img loading="lazy" decoding="async" width="150" height="102" src="../wp-content/uploads/2024/07/%d0%a1%d0%bd%d0%b8%d0%bc%d0%be%d0%ba-%d1%8d%d0%ba%d1%80%d0%b0%d0%bd%d0%b0-2024-07-03-%d0%b2-11.21.55-150x102.png" class="attachment-thumbnail size-thumbnail" alt="" /></a>
			</div></figure><figure class='gallery-item'>
			<div class='gallery-icon landscape'>
				<a data-elementor-open-lightbox="yes" data-elementor-lightbox-slideshow="d07ed37" data-elementor-lightbox-title="Снимок экрана 2024-07-04 в 16.30.14" data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NTI4LCJ1cmwiOiJodHRwczpcL1wvdHJhZGVob3VzZS1tYWxheXNpYS5jb21cL3dwLWNvbnRlbnRcL3VwbG9hZHNcLzIwMjRcLzA3XC9cdTA0MjFcdTA0M2RcdTA0MzhcdTA0M2NcdTA0M2VcdTA0M2EtXHUwNDRkXHUwNDNhXHUwNDQwXHUwNDMwXHUwNDNkXHUwNDMwLTIwMjQtMDctMDQtXHUwNDMyLTE2LjMwLjE0LnBuZyIsInNsaWRlc2hvdyI6ImQwN2VkMzcifQ%3D%3D" href='wp-content/uploads/2024/07/%d0%a1%d0%bd%d0%b8%d0%bc%d0%be%d0%ba-%d1%8d%d0%ba%d1%80%d0%b0%d0%bd%d0%b0-2024-07-04-%d0%b2-16.30.14.png'><img loading="lazy" decoding="async" width="150" height="75" src="../wp-content/uploads/2024/07/%d0%a1%d0%bd%d0%b8%d0%bc%d0%be%d0%ba-%d1%8d%d0%ba%d1%80%d0%b0%d0%bd%d0%b0-2024-07-04-%d0%b2-16.30.14-150x75.png" class="attachment-thumbnail size-thumbnail" alt="" /></a>
			</div></figure><figure class='gallery-item'>
			<div class='gallery-icon landscape'>
				<a data-elementor-open-lightbox="yes" data-elementor-lightbox-slideshow="d07ed37" data-elementor-lightbox-title="Снимок экрана 2024-07-04 в 16.29.52" data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NTI5LCJ1cmwiOiJodHRwczpcL1wvdHJhZGVob3VzZS1tYWxheXNpYS5jb21cL3dwLWNvbnRlbnRcL3VwbG9hZHNcLzIwMjRcLzA3XC9cdTA0MjFcdTA0M2RcdTA0MzhcdTA0M2NcdTA0M2VcdTA0M2EtXHUwNDRkXHUwNDNhXHUwNDQwXHUwNDMwXHUwNDNkXHUwNDMwLTIwMjQtMDctMDQtXHUwNDMyLTE2LjI5LjUyLnBuZyIsInNsaWRlc2hvdyI6ImQwN2VkMzcifQ%3D%3D" href='wp-content/uploads/2024/07/%d0%a1%d0%bd%d0%b8%d0%bc%d0%be%d0%ba-%d1%8d%d0%ba%d1%80%d0%b0%d0%bd%d0%b0-2024-07-04-%d0%b2-16.29.52.png'><img loading="lazy" decoding="async" width="150" height="74" src="../wp-content/uploads/2024/07/%d0%a1%d0%bd%d0%b8%d0%bc%d0%be%d0%ba-%d1%8d%d0%ba%d1%80%d0%b0%d0%bd%d0%b0-2024-07-04-%d0%b2-16.29.52-150x74.png" class="attachment-thumbnail size-thumbnail" alt="" /></a>
			</div></figure><figure class='gallery-item'>
			<div class='gallery-icon landscape'>
				<a data-elementor-open-lightbox="yes" data-elementor-lightbox-slideshow="d07ed37" data-elementor-lightbox-title="Снимок экрана 2024-07-04 в 16.29.44" data-e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6NTMwLCJ1cmwiOiJodHRwczpcL1wvdHJhZGVob3VzZS1tYWxheXNpYS5jb21cL3dwLWNvbnRlbnRcL3VwbG9hZHNcLzIwMjRcLzA3XC9cdTA0MjFcdTA0M2RcdTA0MzhcdTA0M2NcdTA0M2VcdTA0M2EtXHUwNDRkXHUwNDNhXHUwNDQwXHUwNDMwXHUwNDNkXHUwNDMwLTIwMjQtMDctMDQtXHUwNDMyLTE2LjI5LjQ0LnBuZyIsInNsaWRlc2hvdyI6ImQwN2VkMzcifQ%3D%3D" href='wp-content/uploads/2024/07/%d0%a1%d0%bd%d0%b8%d0%bc%d0%be%d0%ba-%d1%8d%d0%ba%d1%80%d0%b0%d0%bd%d0%b0-2024-07-04-%d0%b2-16.29.44.png'><img loading="lazy" decoding="async" width="150" height="72" src="../wp-content/uploads/2024/07/%d0%a1%d0%bd%d0%b8%d0%bc%d0%be%d0%ba-%d1%8d%d0%ba%d1%80%d0%b0%d0%bd%d0%b0-2024-07-04-%d0%b2-16.29.44-150x72.png" class="attachment-thumbnail size-thumbnail" alt="" /></a>
			</div></figure>
		</div>
		</div>
				</div>
				</div>
					</div>
		</div>
					</div>
		</section>
  </template>
  
  <script>
  export default {
      name: "PartnersComponent"
  }
  </script>
  
  <style scoped lang="scss">
  
  </style>