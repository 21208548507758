<template>
	<section class="elementor-section elementor-top-section elementor-element elementor-element-bf3dd1f elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="bf3dd1f" data-element_type="section" id="core">
							<div class="elementor-background-overlay"></div>
							<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-74267493" data-id="74267493" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-25e7aed4 elementor-invisible elementor-widget elementor-widget-heading" data-id="25e7aed4" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;slideInDown&quot;}" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h2 class="elementor-heading-title elementor-size-default">OUR <span >CORE BUSINESSES</span></h2>		</div>
				</div>
				<section class="elementor-section elementor-inner-section elementor-element elementor-element-783cfb9a elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="783cfb9a" data-element_type="section">
						<div class="elementor-container elementor-column-gap-default">
					<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-5eacfda6 elementor-invisible" data-id="5eacfda6" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;slideInRight&quot;}">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-7ee1bed elementor-position-top elementor-widget elementor-widget-image-box" data-id="7ee1bed" data-element_type="widget" data-widget_type="image-box.default">
				<div class="elementor-widget-container">
<div class="elementor-image-box-wrapper"><figure class="elementor-image-box-img"><a href="selection-of-customers-and-partners-in-malaysia-and-asean/index.html" tabindex="-1"><img decoding="async" width="1685" height="1685" src="@/wp-content/uploads/2024/03/pexels-tom-fisk-3057960-scaled-e1710319242519.jpg" class="attachment-full size-full wp-image-22" alt="" /></a></figure><div class="elementor-image-box-content"><h3 class="elementor-image-box-title"><a href="selection-of-customers-and-partners-in-malaysia-and-asean/index.html">Selection of customers and partners in Malaysia and ASEAN</a></h3></div></div>		</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-0fd3382 elementor-invisible" data-id="0fd3382" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;slideInRight&quot;}">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-af6c612 elementor-position-top elementor-widget elementor-widget-image-box" data-id="af6c612" data-element_type="widget" data-widget_type="image-box.default">
				<div class="elementor-widget-container">
			<div class="elementor-image-box-wrapper"><figure class="elementor-image-box-img"><a href="import-and-export-facilitation-through-malaysia/index.html" tabindex="-1"><img decoding="async" width="1080" height="1080" src="@/wp-content/uploads/2024/03/1-4.png" class="attachment-full size-full wp-image-130" alt="" /></a></figure><div class="elementor-image-box-content"><h3 class="elementor-image-box-title"><a href="import-and-export-facilitation-through-malaysia/index.html">Market research and the analysis of  export activities </a></h3></div></div>		</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-5de854e elementor-invisible" data-id="5de854e" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;slideInRight&quot;}">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-5bcdfd0 elementor-position-top elementor-widget elementor-widget-image-box" data-id="5bcdfd0" data-element_type="widget" data-widget_type="image-box.default">
				<div class="elementor-widget-container">
			<div class="elementor-image-box-wrapper"><figure class="elementor-image-box-img"><a href="market-research-and-analysis-for-export-activities/index.html" tabindex="-1"><img loading="lazy" decoding="async" width="1080" height="1080" src="@/wp-content/uploads/2024/03/2-3.png" class="attachment-full size-full wp-image-131" alt="" /></a></figure><div class="elementor-image-box-content"><h3 class="elementor-image-box-title"><a href="market-research-and-analysis-for-export-activities/index.html">Import and export of goods through <br>Malaysia </a></h3></div></div>		</div>
				</div>
					</div>
		</div>
				<div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-4850aa91 elementor-invisible" data-id="4850aa91" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;slideInRight&quot;}">
			<div class="elementor-widget-wrap elementor-element-populated">
						<div class="elementor-element elementor-element-3808fd87 elementor-position-top elementor-widget elementor-widget-image-box" data-id="3808fd87" data-element_type="widget" data-widget_type="image-box.default">
				<div class="elementor-widget-container">
			<div class="elementor-image-box-wrapper"><figure class="elementor-image-box-img"><a href="logistics-and-customs-clearance-solution/index.html" tabindex="-1"><img loading="lazy" decoding="async" width="1706" height="1706" src="@/wp-content/uploads/2024/03/pexels-frans-van-heerden-1624695-scaled-e1710319266909.jpg" class="attachment-full size-full wp-image-21" alt="" /></a></figure><div class="elementor-image-box-content"><h3 class="elementor-image-box-title"><a href="logistics-and-customs-clearance-solution/index.html">Logistics and <br>Custom <br>Clearance</a></h3></div></div>		</div>
				</div>
					</div>
		</div>
					</div>
		</section>
					</div>
		</div>
					</div>
		</section>
  </template>
  
  <script>
  export default {
      name: "CoreBusinessComponent"
  }
  </script>
  
  <style scoped lang="scss">
	
  </style>