import { createApp, } from "vue"
import App from "./App.vue"
import router from "./router"
import { createPinia } from 'pinia'
import {useLangStore} from '@/stores/lang';
import "@/assets/somestyles.css";
import "@/assets/page.css";
import "@/assets/posts.css";
import "@/assets/frontend-lite.min.css";
import "@/assets/animations.min.css";
import "@/assets/widget-icon-list.min.css";
import "@/assets/style.min.css";
import "@/assets/widget-nav-menu.min.css";
import "@/assets/menu.css";
import "@/assets/footer.css";

const app = createApp(App);
const pinia = createPinia()

app.use(router);
app.use(pinia);

app.config.globalProperties.$langStore = useLangStore();

app.mount("#app")
